import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { User } from '../../interfaces/user/user.interface';

@Component({
  selector: 'app-user-details-panel',
  templateUrl: './user-details-panel.component.html',
  styleUrls: [ './user-details-panel.component.scss' ]
})

export class UserDetailsPanelComponent {
  @Input() file = '';
  @Input() userData?: User;

  constructor(
    private readonly router: Router
  ) {}

  goToTermsOfUse(): void {
    this.router?.navigateByUrl('terms-of-use');
  }
}
