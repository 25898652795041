<div class="avatar-container" *transloco="let t">
    <div class="image-container" *ngIf="userStateService.userAvatarUrl(); else placeholder"
    [ngClass]="{'box-shadow-avatar': !!userStateService.userAvatarUrl()}">
        <img class="image-container" alt="" [cxImgToken]="token" [url]="userStateService.userAvatarUrl()">
    </div>

    <ng-template #placeholder>
        <div class="image-container avatar-placeholder">
        </div>
    </ng-template>

    <cx-menu class="avatar-menu" [icon]="'photo_camera'">
        <cx-menu-item (click)="avatarInput.click()" *ngIf="!userStateService.userAvatarUrl()">
            <mat-icon>add_a_photo</mat-icon>
            <span>{{ t('avatar.add') }}</span>
        </cx-menu-item>
        <cx-menu-item (click)="avatarInput.click()" *ngIf="userStateService.userAvatarUrl()">
            <mat-icon>edit</mat-icon>
            <span>{{ t('avatar.edit') }}</span>
        </cx-menu-item>
        <cx-menu-item (click)="onDeleteAvatarClick()" *ngIf="userStateService.userAvatarUrl()">
            <mat-icon>delete</mat-icon>
            <span>{{ t('avatar.remove') }}</span>
        </cx-menu-item>
    </cx-menu>
    <input #avatarInput type="file" id="avatar-input-file" accept=".png, .jpg, .jpeg" (change)="onFileChange($event)"/>
</div>
