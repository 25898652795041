import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject } from '@angular/core';

import { MsalService } from '@azure/msal-angular';
import { CxDialogService } from '@bbraun/cortex/dialog';
import { HTTP_STATUS_CODES } from '@bbraun/cortex/number-input';
import { CxSnackbarService } from '@bbraun/cortex/snackbar';
import { TranslocoService } from '@ngneat/transloco';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { concatMap, filter, map, Observable, pipe, switchMap, take } from 'rxjs';

import { DEFAULT_LANGUAGE, USER_LANGUAGES } from '../../constants/user-languages/user-languages.constants';
import { AVATAR_FILE_MAX_SIZE } from '../../constants/validation/validation.constants';
import { Language } from '../../interfaces/language/language.interface';
import { User } from '../../interfaces/user/user.interface';
import { UserService } from '../../service/user/user.service';

interface UserState {
  user: User | undefined;
  userLoading: boolean;
}

const initialState: UserState = {
  user: undefined,
  userLoading: true
};

export const UserStateService = signalStore(
  { providedIn: 'root', protectedState: false },
  withState<UserState>(initialState),
  withComputed( store => {
    return {
      userAvatarUrl: computed(() => store.user()?.avatarUrl ?? '')
    };
  }),
  withMethods(store => {
    const userService = inject(UserService);
    const transloco = inject(TranslocoService);
    const authService = inject(MsalService);

    function handleSelectedLanguage(userLanguage: string): void {
      const foundLanguage: Language | undefined = USER_LANGUAGES.find(
        (language: Language) => language.code === userLanguage);
      if (foundLanguage) {
        transloco.setActiveLang(foundLanguage.code);
      } else {
        transloco.setActiveLang(DEFAULT_LANGUAGE);
      }
    }
    return {
      loadUser: rxMethod<void>(pipe(
        switchMap(() => {
          return userService.getUser().pipe(tapResponse(
            (user: User) => {
              patchState(store, { user, userLoading: false });
              handleSelectedLanguage(user.attributes.language);
            },
            (err: HttpErrorResponse) => {
              if (err.status === HTTP_STATUS_CODES.NOT_FOUND) {
                authService.logout({ idTokenHint: authService.instance.getAllAccounts()[0].idToken });
              }
            }
          ));
        })
      ))
    };
  }),
  withMethods(store => {
    const userService = inject(UserService);
    const snackbarService = inject(CxSnackbarService);
    const translocoService = inject(TranslocoService);
    const dialogService = inject(CxDialogService);

    function showConfirmDialog(): Observable<boolean> {
      return dialogService.openDeleteDialog(
        translocoService.translate('confirmAvatarDeleteDialog.title'),
        translocoService.translate('confirmAvatarDeleteDialog.description'),
        translocoService.translate('confirmAvatarDeleteDialog.confirmButton'),
        translocoService.translate('confirmAvatarDeleteDialog.cancelButton'))
        .pipe(take(1));
    }

    return {
      updateAvatar: rxMethod<Blob>(pipe(
        filter((avatarImage: Blob) => {
          const isImageTooLarge = avatarImage.size >= AVATAR_FILE_MAX_SIZE;
          if (isImageTooLarge) {
            snackbarService.error(translocoService.translate('details.errorAvatarSize'));
          }
          return !isImageTooLarge;
        }),
        switchMap((avatarFile: Blob) => {
          return userService.updateAvatar(avatarFile).pipe(tapResponse(
            (userAvatarUrl: string) => {
              patchState(store, { user: { ...store.user()!, avatarUrl: userAvatarUrl }});
              snackbarService.success(translocoService.translate('details.successUserUpdate'));
            },
            (err: Error) => {
              snackbarService.error(err.message);
            }
          ));
        })
      )),
      deleteAvatar: rxMethod<void>(pipe(
        concatMap(() => showConfirmDialog().pipe(map((result: boolean) => result))),
        filter((result: boolean) => result),
        switchMap(() =>
          userService.deleteAvatar().pipe(tapResponse(
            () => {
              patchState(store, { user: { ...store.user()!, avatarUrl: '' }});
              snackbarService.success(translocoService.translate('details.successUserUpdate'));
            },
            (err: Error) => {
              snackbarService.error(err.message);
            }
          ))
        )
      ))
    };
  }),
  withHooks({
    onInit({ loadUser }) {
      loadUser();
    }
  })
);
