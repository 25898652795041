<div *transloco="let t" class="pt-m pl-sm pr-sm pb-ml round-card">

  <app-avatar class="avatar-center" />

  <h2 class="mb-xs">{{ userData?.firstName }} {{ userData?.lastName }}</h2>

  <h5 class="mb-m mt-0">{{ userData?.attributes?.title }}</h5>

  <div class="d-flex justify-content-start border-bottom" *ngIf="userData?.attributes?.organisation">
    <mat-icon class="material-icons">local_hospital</mat-icon>
    <span class="ml-sm">{{ userData?.attributes?.organisation }}</span>
  </div>
  <div class="d-flex justify-content-start border-bottom" *ngIf="userData?.attributes?.department">
    <mat-icon class="material-icons">park</mat-icon>
    <span class="ml-sm">{{ userData?.attributes?.department }}</span>
  </div>

    <div class="pt-m" *transloco="let t">
        <cx-navigation-drawer-button
                class="routing-button"
                name="{{t('menu.home')}}"
                icon="home"
                routerLink=""
        />

        <cx-navigation-drawer-button
                class="routing-button"
                name="{{t('menu.organizations')}}"
                icon="view_list"
                routerLink="/organizations"
        />

        <cx-navigation-drawer-button
                class="routing-button"
                name="{{t('menu.applications')}}"
                icon="apps"
                routerLink="/applications"
        />
    </div>

  <div class="links mb-s">
    <a href="https://www.bbraun.com/en/privacy-policy.html" target='_blank' class="small-link"
    >{{ t('links.privacyPolicy') }}</a
    >
    <a
      data-cy="link-terms-of-use"
      class="link small-link small-link mr-s ml-s"
      target="_self"
      (click)="goToTermsOfUse()"
    >{{ t('links.termsOfUse') }}</a
    >
    <a href="https://www.bbraun.com" target='_blank' class="small-link"
    >{{ t('links.cookiePolicy') }}</a
    >
  </div>
</div>
