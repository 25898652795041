export class AzureInsightsConstants {
  public static readonly DEV_KEY =
    // eslint-disable-next-line max-len
    'InstrumentationKey=d6c9dd86-0023-4d46-955b-819fce850beb;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/';

  public static readonly TEST_KEY =
    // eslint-disable-next-line max-len
    'InstrumentationKey=c36921d5-aac4-4953-ae0b-6b0884eba95c;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/';

  public static readonly PROD_KEY =
    // eslint-disable-next-line max-len
    'InstrumentationKey=4b967428-3dfd-46d2-95c2-e40572d33c50;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/';
}
