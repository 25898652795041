// We are dynamically changing the environment of ng depending on the host URL
// No replace files are used
import { AzureInsightsConstants } from '../app/modules/shared/constants/azure-insights/azure-insights.constants';

export const environment = {
  api: '',
  develop: true,
  environment: 'DEV',
  enableProdMode: true,
  backendUrl:
    'https://api.myprofile.dev.account.bbraun.com/api',
  backendAuthUrl:
    'https://api.dev.account.bbraun.com/auth',
  postLogoutRedirectUri: 'localhost:8080',
  production: false,
  monitoring: {
    enable: true,
    azureInsightsKey: AzureInsightsConstants.DEV_KEY
  },
  msalConfig: {
    auth: {
      clientId: '3b7f5a2b-3841-46c5-a377-955d12fcaadc'
    }
  },
  apiConfig: {
    scopes: [
      'openid',
      'profile',
      'offline_access',
      'https://dev.account.bbraun.com/auth/gpr/User.Read',
      'https://dev.account.bbraun.com/auth/gpr/User.Write'
    ],
    uri: 'https://api.myprofile.dev.account.bbraun.com/api/'
  },
  authApiConfig: {
    scopes: [ 'https://dev.account.bbraun.com/auth/check/Relations.User.Read' ],
    uri: 'https://api.dev.account.bbraun.com/auth'
  },
  b2cPolicies: {
    names: {
      signUpSignIn: 'B2C_1A__SIGNUP_SIGNIN_MFA',
      resetPassword: 'B2C_1A__MFARESET',
      editProfile: 'B2C_1A__PROFILEEDIT',
      editEmail: 'B2C_1A__EMAILEDIT',
      resetMfa: 'B2C_1A__MFARESET',
      deleteUser: 'B2C_1A__ACCOUNTDELETE'
    },
    authorities: {
      signUpSignIn: {
        authority:
          'https://dev.account.bbraun.com/69475234-3a1e-49e2-bb59-4cbe2d13b0e3/B2C_1A__SIGNUP_SIGNIN_MFA/'
      },
      resetPassword: {
        authority:
          'https://dev.account.bbraun.com/69475234-3a1e-49e2-bb59-4cbe2d13b0e3/B2C_1A__PASSWORDCHANGE/'
      },
      editProfile: {
        authority:
          'https://dev.account.bbraun.com/69475234-3a1e-49e2-bb59-4cbe2d13b0e3/B2C_1A__PROFILEEDIT/'
      },
      editEmail: {
        authority:
          'https://dev.account.bbraun.com/69475234-3a1e-49e2-bb59-4cbe2d13b0e3/B2C_1A__EMAILEDIT/'
      },
      resetMfa: {
        authority:
          'https://dev.account.bbraun.com/69475234-3a1e-49e2-bb59-4cbe2d13b0e3/B2C_1A__MFARESET/'
      },
      deleteUser: {
        authority:
          'https://dev.account.bbraun.com/69475234-3a1e-49e2-bb59-4cbe2d13b0e3/B2C_1A__ACCOUNTDELETE/'
      }
    },
    authorityDomain: 'dev.account.bbraun.com'
  }
};
