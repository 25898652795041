<div *transloco="let t">
    <img alt="" id="image" height="600" [src]="sanitizedUrl">
    <div class="crop-actions">
        <button class="button-margin-right" color="accent" mat-stroked-button (click)="cancel()">
            {{ t('avatar.cancelAction') }}
        </button>
        <button class="button-margin-right" mat-stroked-button (click)="reset()">
            {{ t('avatar.resetAction') }}
        </button>
        <button color="accent" mat-flat-button (click)="crop()">
            {{ t('avatar.doneAction') }}
        </button>
    </div>
</div>
